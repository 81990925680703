jQuery(document).ready(function ($) {
  $(document).on('click', '.btn-support', function () {
    const commentID = $(this).data('id');
    $('#supportModal #comment-id').val(commentID);
  });

  /*

		$(".btn-support").live("click", function( event ){

		event.preventDefault();

		var id = $(this).data('second-id');

		console.log( id );

		$.post(
			// see tip #1 for how we declare global javascript variables
			bonesCommentsLikesHelper.ajaxUrl,
			{
				action : 'add-comment-like',
				// other parameters can be added along with "action"
				nonce : bonesCommentsLikesHelper.ajaxNonce,
				//
				comment_id : id
			},
			function( response ) {
				console.log(response);
			}
		);

		}); */
});
